<template>
  <div style="background: #f9545a" class="mt-16 py-16 px-12 text-center white--text">
    <div class="mx-auto">
      <h2 class="mb-2 mt-8" style="font-size: 30px">
        {{$t('features.header_features_bold_first')}}
        <span style="font-weight: 300"> {{$t('features.header_features')}} </span><br>
        {{$t('features.header_features_bold')}}
      </h2>
      <hr style="width: 80px" class="mx-auto mb-16">
      <v-row style="max-width: 900px" class="mx-auto mb-8">
        <v-col>
          <h2 class="mb-2">{{$t('features.feature_shorts.1_header')}}</h2>
          <p class="body-1" style="font-weight: 300; min-width: 180px">{{$t('features.feature_shorts.1_text')}}</p>
        </v-col>
        <v-col>
          <h2 class="mb-2">{{$t('features.feature_shorts.2_header')}}</h2>
          <p class="body-1" style="font-weight: 300; min-width: 180px">{{$t('features.feature_shorts.2_text')}}</p>
        </v-col>
        <v-col>
          <h2 class="mb-2">{{$t('features.feature_shorts.3_header')}}</h2>
          <p class="body-1" style="font-weight: 300; min-width: 180px">{{$t('features.feature_shorts.3_text')}}</p>
        </v-col>
        <v-col>
          <h2 class="mb-2">{{$t('features.feature_shorts.4_header')}}</h2>
          <p class="body-1" style="font-weight: 300; min-width: 180px">{{$t('features.feature_shorts.4_text')}}</p>
        </v-col>
      </v-row>
    </div>

  </div>


</template>

<script>
export default {
  name: "FeatureListMobile"
}
</script>

<style scoped>

</style>